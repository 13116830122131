@use "../../../global" as *;

@include responsive using ($prefix) {
  // padding
  @each $name, $value in $spacing {
    .#{$prefix}u-p-#{$name} {
      padding: $value !important;
    }
  }

  // padding-top
  @each $name, $value in $spacing {
    .#{$prefix}u-pt-#{$name} {
      padding-top: $value !important;
    }
  }

  // padding-right
  @each $name, $value in $spacing {
    .#{$prefix}u-pr-#{$name} {
      padding-right: $value !important;
    }
  }

  // padding-bottom
  @each $name, $value in $spacing {
    .#{$prefix}u-pb-#{$name} {
      padding-bottom: $value !important;
    }
  }

  // padding-left
  @each $name, $value in $spacing {
    .#{$prefix}u-pl-#{$name} {
      padding-left: $value !important;
    }
  }
}