.u-content-center {
  align-content: center !important;
}

.u-content-start {
  align-content: flex-start !important;
}

.u-content-end {
  align-content: flex-end !important;
}

.u-content-between {
  align-content: space-between !important;
}

.u-content-around {
  align-content: space-around !important;
}

.u-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-content-center {
    align-content: center !important;
  }
  .md\:u-content-start {
    align-content: flex-start !important;
  }
  .md\:u-content-end {
    align-content: flex-end !important;
  }
  .md\:u-content-between {
    align-content: space-between !important;
  }
  .md\:u-content-around {
    align-content: space-around !important;
  }
  .md\:u-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-content-center {
    align-content: center !important;
  }
  .sm\:u-content-start {
    align-content: flex-start !important;
  }
  .sm\:u-content-end {
    align-content: flex-end !important;
  }
  .sm\:u-content-between {
    align-content: space-between !important;
  }
  .sm\:u-content-around {
    align-content: space-around !important;
  }
  .sm\:u-content-evenly {
    align-content: space-evenly !important;
  }
}
.u-items-start {
  align-items: flex-start !important;
}

.u-items-end {
  align-items: flex-end !important;
}

.u-items-center {
  align-items: center !important;
}

.u-items-baseline {
  align-items: baseline !important;
}

.u-items-stretch {
  align-items: stretch !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-items-start {
    align-items: flex-start !important;
  }
  .md\:u-items-end {
    align-items: flex-end !important;
  }
  .md\:u-items-center {
    align-items: center !important;
  }
  .md\:u-items-baseline {
    align-items: baseline !important;
  }
  .md\:u-items-stretch {
    align-items: stretch !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-items-start {
    align-items: flex-start !important;
  }
  .sm\:u-items-end {
    align-items: flex-end !important;
  }
  .sm\:u-items-center {
    align-items: center !important;
  }
  .sm\:u-items-baseline {
    align-items: baseline !important;
  }
  .sm\:u-items-stretch {
    align-items: stretch !important;
  }
}
.u-self-auto {
  align-self: auto !important;
}

.u-self-start {
  align-self: flex-start !important;
}

.u-self-end {
  align-self: flex-end !important;
}

.u-self-center {
  align-self: center !important;
}

.u-self-stretch {
  align-self: stretch !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-self-auto {
    align-self: auto !important;
  }
  .md\:u-self-start {
    align-self: flex-start !important;
  }
  .md\:u-self-end {
    align-self: flex-end !important;
  }
  .md\:u-self-center {
    align-self: center !important;
  }
  .md\:u-self-stretch {
    align-self: stretch !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-self-auto {
    align-self: auto !important;
  }
  .sm\:u-self-start {
    align-self: flex-start !important;
  }
  .sm\:u-self-end {
    align-self: flex-end !important;
  }
  .sm\:u-self-center {
    align-self: center !important;
  }
  .sm\:u-self-stretch {
    align-self: stretch !important;
  }
}
.u-justify-start {
  justify-content: flex-start !important;
}

.u-justify-end {
  justify-content: flex-end !important;
}

.u-justify-center {
  justify-content: center !important;
}

.u-justify-between {
  justify-content: space-between !important;
}

.u-justify-around {
  justify-content: space-around !important;
}

.u-justify-evenly {
  justify-content: space-evenly !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-justify-start {
    justify-content: flex-start !important;
  }
  .md\:u-justify-end {
    justify-content: flex-end !important;
  }
  .md\:u-justify-center {
    justify-content: center !important;
  }
  .md\:u-justify-between {
    justify-content: space-between !important;
  }
  .md\:u-justify-around {
    justify-content: space-around !important;
  }
  .md\:u-justify-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-justify-start {
    justify-content: flex-start !important;
  }
  .sm\:u-justify-end {
    justify-content: flex-end !important;
  }
  .sm\:u-justify-center {
    justify-content: center !important;
  }
  .sm\:u-justify-between {
    justify-content: space-between !important;
  }
  .sm\:u-justify-around {
    justify-content: space-around !important;
  }
  .sm\:u-justify-evenly {
    justify-content: space-evenly !important;
  }
}
.u-justify-items-auto {
  justify-items: auto !important;
}

.u-justify-items-start {
  justify-items: start !important;
}

.u-justify-items-end {
  justify-items: end !important;
}

.u-justify-items-center {
  justify-items: center !important;
}

.u-justify-items-stretch {
  justify-items: stretch !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-justify-items-auto {
    justify-items: auto !important;
  }
  .md\:u-justify-items-start {
    justify-items: start !important;
  }
  .md\:u-justify-items-end {
    justify-items: end !important;
  }
  .md\:u-justify-items-center {
    justify-items: center !important;
  }
  .md\:u-justify-items-stretch {
    justify-items: stretch !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-justify-items-auto {
    justify-items: auto !important;
  }
  .sm\:u-justify-items-start {
    justify-items: start !important;
  }
  .sm\:u-justify-items-end {
    justify-items: end !important;
  }
  .sm\:u-justify-items-center {
    justify-items: center !important;
  }
  .sm\:u-justify-items-stretch {
    justify-items: stretch !important;
  }
}
.u-justify-self-auto {
  justify-self: auto !important;
}

.u-justify-self-start {
  justify-self: start !important;
}

.u-justify-self-end {
  justify-self: end !important;
}

.u-justify-self-center {
  justify-self: center !important;
}

.u-justify-self-stretch {
  justify-self: stretch !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-justify-self-auto {
    justify-self: auto !important;
  }
  .md\:u-justify-self-start {
    justify-self: start !important;
  }
  .md\:u-justify-self-end {
    justify-self: end !important;
  }
  .md\:u-justify-self-center {
    justify-self: center !important;
  }
  .md\:u-justify-self-stretch {
    justify-self: stretch !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-justify-self-auto {
    justify-self: auto !important;
  }
  .sm\:u-justify-self-start {
    justify-self: start !important;
  }
  .sm\:u-justify-self-end {
    justify-self: end !important;
  }
  .sm\:u-justify-self-center {
    justify-self: center !important;
  }
  .sm\:u-justify-self-stretch {
    justify-self: stretch !important;
  }
}
.u-background-black {
  background-color: #000000 !important;
}

.u-background-white {
  background-color: #ffffff !important;
}

.u-background-red {
  background-color: #F0584A !important;
}

.u-background-blue {
  background-color: #006BB4 !important;
}

.u-background-lightblue {
  background-color: #0095D9 !important;
}

.u-background-paleblue {
  background-color: rgba(0, 107, 180, 0.1) !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-background-black {
    background-color: #000000 !important;
  }
  .md\:u-background-white {
    background-color: #ffffff !important;
  }
  .md\:u-background-red {
    background-color: #F0584A !important;
  }
  .md\:u-background-blue {
    background-color: #006BB4 !important;
  }
  .md\:u-background-lightblue {
    background-color: #0095D9 !important;
  }
  .md\:u-background-paleblue {
    background-color: rgba(0, 107, 180, 0.1) !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-background-black {
    background-color: #000000 !important;
  }
  .sm\:u-background-white {
    background-color: #ffffff !important;
  }
  .sm\:u-background-red {
    background-color: #F0584A !important;
  }
  .sm\:u-background-blue {
    background-color: #006BB4 !important;
  }
  .sm\:u-background-lightblue {
    background-color: #0095D9 !important;
  }
  .sm\:u-background-paleblue {
    background-color: rgba(0, 107, 180, 0.1) !important;
  }
}
.u-color-black {
  color: #000000 !important;
}

.u-color-white {
  color: #ffffff !important;
}

.u-color-red {
  color: #F0584A !important;
}

.u-color-blue {
  color: #006BB4 !important;
}

.u-color-lightblue {
  color: #0095D9 !important;
}

.u-color-paleblue {
  color: rgba(0, 107, 180, 0.1) !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-color-black {
    color: #000000 !important;
  }
  .md\:u-color-white {
    color: #ffffff !important;
  }
  .md\:u-color-red {
    color: #F0584A !important;
  }
  .md\:u-color-blue {
    color: #006BB4 !important;
  }
  .md\:u-color-lightblue {
    color: #0095D9 !important;
  }
  .md\:u-color-paleblue {
    color: rgba(0, 107, 180, 0.1) !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-color-black {
    color: #000000 !important;
  }
  .sm\:u-color-white {
    color: #ffffff !important;
  }
  .sm\:u-color-red {
    color: #F0584A !important;
  }
  .sm\:u-color-blue {
    color: #006BB4 !important;
  }
  .sm\:u-color-lightblue {
    color: #0095D9 !important;
  }
  .sm\:u-color-paleblue {
    color: rgba(0, 107, 180, 0.1) !important;
  }
}
.u-emphasis-thin {
  font-weight: 100 !important;
}

.u-emphasis-extralight {
  font-weight: 200 !important;
}

.u-emphasis-light {
  font-weight: 300 !important;
}

.u-emphasis-regular {
  font-weight: 400 !important;
}

.u-emphasis-medium {
  font-weight: 500 !important;
}

.u-emphasis-semibold {
  font-weight: 600 !important;
}

.u-emphasis-bold {
  font-weight: 700 !important;
}

.u-emphasis-extrabold {
  font-weight: 800 !important;
}

.u-emphasis-black {
  font-weight: 900 !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-emphasis-thin {
    font-weight: 100 !important;
  }
  .md\:u-emphasis-extralight {
    font-weight: 200 !important;
  }
  .md\:u-emphasis-light {
    font-weight: 300 !important;
  }
  .md\:u-emphasis-regular {
    font-weight: 400 !important;
  }
  .md\:u-emphasis-medium {
    font-weight: 500 !important;
  }
  .md\:u-emphasis-semibold {
    font-weight: 600 !important;
  }
  .md\:u-emphasis-bold {
    font-weight: 700 !important;
  }
  .md\:u-emphasis-extrabold {
    font-weight: 800 !important;
  }
  .md\:u-emphasis-black {
    font-weight: 900 !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-emphasis-thin {
    font-weight: 100 !important;
  }
  .sm\:u-emphasis-extralight {
    font-weight: 200 !important;
  }
  .sm\:u-emphasis-light {
    font-weight: 300 !important;
  }
  .sm\:u-emphasis-regular {
    font-weight: 400 !important;
  }
  .sm\:u-emphasis-medium {
    font-weight: 500 !important;
  }
  .sm\:u-emphasis-semibold {
    font-weight: 600 !important;
  }
  .sm\:u-emphasis-bold {
    font-weight: 700 !important;
  }
  .sm\:u-emphasis-extrabold {
    font-weight: 800 !important;
  }
  .sm\:u-emphasis-black {
    font-weight: 900 !important;
  }
}
.u-family-noto-sans {
  font-family: Noto Sans JP, sans-serif;
}

@media screen and (max-width: 1024px) {
  .md\:u-family-noto-sans {
    font-family: Noto Sans JP, sans-serif;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-family-noto-sans {
    font-family: Noto Sans JP, sans-serif;
  }
}
.u-leading-none {
  line-height: 1 !important;
}

.u-leading-sm {
  line-height: 1.25 !important;
}

.u-leading-normal {
  line-height: 1.5 !important;
}

.u-leading-lg {
  line-height: 2 !important;
}

.u-leading-xl {
  line-height: 2.5 !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-leading-none {
    line-height: 1 !important;
  }
  .md\:u-leading-sm {
    line-height: 1.25 !important;
  }
  .md\:u-leading-normal {
    line-height: 1.5 !important;
  }
  .md\:u-leading-lg {
    line-height: 2 !important;
  }
  .md\:u-leading-xl {
    line-height: 2.5 !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-leading-none {
    line-height: 1 !important;
  }
  .sm\:u-leading-sm {
    line-height: 1.25 !important;
  }
  .sm\:u-leading-normal {
    line-height: 1.5 !important;
  }
  .sm\:u-leading-lg {
    line-height: 2 !important;
  }
  .sm\:u-leading-xl {
    line-height: 2.5 !important;
  }
}
.u-m-none {
  margin: 0 !important;
}

.u-m-3xs {
  margin: 0.125rem !important;
}

.u-m-2xs {
  margin: 0.25rem !important;
}

.u-m-xs {
  margin: 0.5rem !important;
}

.u-m-sm {
  margin: 1rem !important;
}

.u-m-md {
  margin: 1.5rem !important;
}

.u-m-lg {
  margin: 2rem !important;
}

.u-m-xl {
  margin: 3.5rem !important;
}

.u-m-2xl {
  margin: 6rem !important;
}

.u-m-3xl {
  margin: 9rem !important;
}

.u-m-auto {
  margin: auto !important;
}

.u-mt-none {
  margin-top: 0 !important;
}

.u-mt-3xs {
  margin-top: 0.125rem !important;
}

.u-mt-2xs {
  margin-top: 0.25rem !important;
}

.u-mt-xs {
  margin-top: 0.5rem !important;
}

.u-mt-sm {
  margin-top: 1rem !important;
}

.u-mt-md {
  margin-top: 1.5rem !important;
}

.u-mt-lg {
  margin-top: 2rem !important;
}

.u-mt-xl {
  margin-top: 3.5rem !important;
}

.u-mt-2xl {
  margin-top: 6rem !important;
}

.u-mt-3xl {
  margin-top: 9rem !important;
}

.u-mt-auto {
  margin-top: auto !important;
}

.u-mr-none {
  margin-right: 0 !important;
}

.u-mr-3xs {
  margin-right: 0.125rem !important;
}

.u-mr-2xs {
  margin-right: 0.25rem !important;
}

.u-mr-xs {
  margin-right: 0.5rem !important;
}

.u-mr-sm {
  margin-right: 1rem !important;
}

.u-mr-md {
  margin-right: 1.5rem !important;
}

.u-mr-lg {
  margin-right: 2rem !important;
}

.u-mr-xl {
  margin-right: 3.5rem !important;
}

.u-mr-2xl {
  margin-right: 6rem !important;
}

.u-mr-3xl {
  margin-right: 9rem !important;
}

.u-mr-auto {
  margin-right: auto !important;
}

.u-mb-none {
  margin-bottom: 0 !important;
}

.u-mb-3xs {
  margin-bottom: 0.125rem !important;
}

.u-mb-2xs {
  margin-bottom: 0.25rem !important;
}

.u-mb-xs {
  margin-bottom: 0.5rem !important;
}

.u-mb-sm {
  margin-bottom: 1rem !important;
}

.u-mb-md {
  margin-bottom: 1.5rem !important;
}

.u-mb-lg {
  margin-bottom: 2rem !important;
}

.u-mb-xl {
  margin-bottom: 3.5rem !important;
}

.u-mb-2xl {
  margin-bottom: 6rem !important;
}

.u-mb-3xl {
  margin-bottom: 9rem !important;
}

.u-mb-auto {
  margin-bottom: auto !important;
}

.u-ml-none {
  margin-left: 0 !important;
}

.u-ml-3xs {
  margin-left: 0.125rem !important;
}

.u-ml-2xs {
  margin-left: 0.25rem !important;
}

.u-ml-xs {
  margin-left: 0.5rem !important;
}

.u-ml-sm {
  margin-left: 1rem !important;
}

.u-ml-md {
  margin-left: 1.5rem !important;
}

.u-ml-lg {
  margin-left: 2rem !important;
}

.u-ml-xl {
  margin-left: 3.5rem !important;
}

.u-ml-2xl {
  margin-left: 6rem !important;
}

.u-ml-3xl {
  margin-left: 9rem !important;
}

.u-ml-auto {
  margin-left: auto !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-m-none {
    margin: 0 !important;
  }
  .md\:u-m-3xs {
    margin: 0.125rem !important;
  }
  .md\:u-m-2xs {
    margin: 0.25rem !important;
  }
  .md\:u-m-xs {
    margin: 0.5rem !important;
  }
  .md\:u-m-sm {
    margin: 1rem !important;
  }
  .md\:u-m-md {
    margin: 1.5rem !important;
  }
  .md\:u-m-lg {
    margin: 2rem !important;
  }
  .md\:u-m-xl {
    margin: 3.5rem !important;
  }
  .md\:u-m-2xl {
    margin: 6rem !important;
  }
  .md\:u-m-3xl {
    margin: 9rem !important;
  }
  .md\:u-m-auto {
    margin: auto !important;
  }
  .md\:u-mt-none {
    margin-top: 0 !important;
  }
  .md\:u-mt-3xs {
    margin-top: 0.125rem !important;
  }
  .md\:u-mt-2xs {
    margin-top: 0.25rem !important;
  }
  .md\:u-mt-xs {
    margin-top: 0.5rem !important;
  }
  .md\:u-mt-sm {
    margin-top: 1rem !important;
  }
  .md\:u-mt-md {
    margin-top: 1.5rem !important;
  }
  .md\:u-mt-lg {
    margin-top: 2rem !important;
  }
  .md\:u-mt-xl {
    margin-top: 3.5rem !important;
  }
  .md\:u-mt-2xl {
    margin-top: 6rem !important;
  }
  .md\:u-mt-3xl {
    margin-top: 9rem !important;
  }
  .md\:u-mt-auto {
    margin-top: auto !important;
  }
  .md\:u-mr-none {
    margin-right: 0 !important;
  }
  .md\:u-mr-3xs {
    margin-right: 0.125rem !important;
  }
  .md\:u-mr-2xs {
    margin-right: 0.25rem !important;
  }
  .md\:u-mr-xs {
    margin-right: 0.5rem !important;
  }
  .md\:u-mr-sm {
    margin-right: 1rem !important;
  }
  .md\:u-mr-md {
    margin-right: 1.5rem !important;
  }
  .md\:u-mr-lg {
    margin-right: 2rem !important;
  }
  .md\:u-mr-xl {
    margin-right: 3.5rem !important;
  }
  .md\:u-mr-2xl {
    margin-right: 6rem !important;
  }
  .md\:u-mr-3xl {
    margin-right: 9rem !important;
  }
  .md\:u-mr-auto {
    margin-right: auto !important;
  }
  .md\:u-mb-none {
    margin-bottom: 0 !important;
  }
  .md\:u-mb-3xs {
    margin-bottom: 0.125rem !important;
  }
  .md\:u-mb-2xs {
    margin-bottom: 0.25rem !important;
  }
  .md\:u-mb-xs {
    margin-bottom: 0.5rem !important;
  }
  .md\:u-mb-sm {
    margin-bottom: 1rem !important;
  }
  .md\:u-mb-md {
    margin-bottom: 1.5rem !important;
  }
  .md\:u-mb-lg {
    margin-bottom: 2rem !important;
  }
  .md\:u-mb-xl {
    margin-bottom: 3.5rem !important;
  }
  .md\:u-mb-2xl {
    margin-bottom: 6rem !important;
  }
  .md\:u-mb-3xl {
    margin-bottom: 9rem !important;
  }
  .md\:u-mb-auto {
    margin-bottom: auto !important;
  }
  .md\:u-ml-none {
    margin-left: 0 !important;
  }
  .md\:u-ml-3xs {
    margin-left: 0.125rem !important;
  }
  .md\:u-ml-2xs {
    margin-left: 0.25rem !important;
  }
  .md\:u-ml-xs {
    margin-left: 0.5rem !important;
  }
  .md\:u-ml-sm {
    margin-left: 1rem !important;
  }
  .md\:u-ml-md {
    margin-left: 1.5rem !important;
  }
  .md\:u-ml-lg {
    margin-left: 2rem !important;
  }
  .md\:u-ml-xl {
    margin-left: 3.5rem !important;
  }
  .md\:u-ml-2xl {
    margin-left: 6rem !important;
  }
  .md\:u-ml-3xl {
    margin-left: 9rem !important;
  }
  .md\:u-ml-auto {
    margin-left: auto !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-m-none {
    margin: 0 !important;
  }
  .sm\:u-m-3xs {
    margin: 0.125rem !important;
  }
  .sm\:u-m-2xs {
    margin: 0.25rem !important;
  }
  .sm\:u-m-xs {
    margin: 0.5rem !important;
  }
  .sm\:u-m-sm {
    margin: 1rem !important;
  }
  .sm\:u-m-md {
    margin: 1.5rem !important;
  }
  .sm\:u-m-lg {
    margin: 2rem !important;
  }
  .sm\:u-m-xl {
    margin: 3.5rem !important;
  }
  .sm\:u-m-2xl {
    margin: 6rem !important;
  }
  .sm\:u-m-3xl {
    margin: 9rem !important;
  }
  .sm\:u-m-auto {
    margin: auto !important;
  }
  .sm\:u-mt-none {
    margin-top: 0 !important;
  }
  .sm\:u-mt-3xs {
    margin-top: 0.125rem !important;
  }
  .sm\:u-mt-2xs {
    margin-top: 0.25rem !important;
  }
  .sm\:u-mt-xs {
    margin-top: 0.5rem !important;
  }
  .sm\:u-mt-sm {
    margin-top: 1rem !important;
  }
  .sm\:u-mt-md {
    margin-top: 1.5rem !important;
  }
  .sm\:u-mt-lg {
    margin-top: 2rem !important;
  }
  .sm\:u-mt-xl {
    margin-top: 3.5rem !important;
  }
  .sm\:u-mt-2xl {
    margin-top: 6rem !important;
  }
  .sm\:u-mt-3xl {
    margin-top: 9rem !important;
  }
  .sm\:u-mt-auto {
    margin-top: auto !important;
  }
  .sm\:u-mr-none {
    margin-right: 0 !important;
  }
  .sm\:u-mr-3xs {
    margin-right: 0.125rem !important;
  }
  .sm\:u-mr-2xs {
    margin-right: 0.25rem !important;
  }
  .sm\:u-mr-xs {
    margin-right: 0.5rem !important;
  }
  .sm\:u-mr-sm {
    margin-right: 1rem !important;
  }
  .sm\:u-mr-md {
    margin-right: 1.5rem !important;
  }
  .sm\:u-mr-lg {
    margin-right: 2rem !important;
  }
  .sm\:u-mr-xl {
    margin-right: 3.5rem !important;
  }
  .sm\:u-mr-2xl {
    margin-right: 6rem !important;
  }
  .sm\:u-mr-3xl {
    margin-right: 9rem !important;
  }
  .sm\:u-mr-auto {
    margin-right: auto !important;
  }
  .sm\:u-mb-none {
    margin-bottom: 0 !important;
  }
  .sm\:u-mb-3xs {
    margin-bottom: 0.125rem !important;
  }
  .sm\:u-mb-2xs {
    margin-bottom: 0.25rem !important;
  }
  .sm\:u-mb-xs {
    margin-bottom: 0.5rem !important;
  }
  .sm\:u-mb-sm {
    margin-bottom: 1rem !important;
  }
  .sm\:u-mb-md {
    margin-bottom: 1.5rem !important;
  }
  .sm\:u-mb-lg {
    margin-bottom: 2rem !important;
  }
  .sm\:u-mb-xl {
    margin-bottom: 3.5rem !important;
  }
  .sm\:u-mb-2xl {
    margin-bottom: 6rem !important;
  }
  .sm\:u-mb-3xl {
    margin-bottom: 9rem !important;
  }
  .sm\:u-mb-auto {
    margin-bottom: auto !important;
  }
  .sm\:u-ml-none {
    margin-left: 0 !important;
  }
  .sm\:u-ml-3xs {
    margin-left: 0.125rem !important;
  }
  .sm\:u-ml-2xs {
    margin-left: 0.25rem !important;
  }
  .sm\:u-ml-xs {
    margin-left: 0.5rem !important;
  }
  .sm\:u-ml-sm {
    margin-left: 1rem !important;
  }
  .sm\:u-ml-md {
    margin-left: 1.5rem !important;
  }
  .sm\:u-ml-lg {
    margin-left: 2rem !important;
  }
  .sm\:u-ml-xl {
    margin-left: 3.5rem !important;
  }
  .sm\:u-ml-2xl {
    margin-left: 6rem !important;
  }
  .sm\:u-ml-3xl {
    margin-left: 9rem !important;
  }
  .sm\:u-ml-auto {
    margin-left: auto !important;
  }
}
.u-p-none {
  padding: 0 !important;
}

.u-p-3xs {
  padding: 0.125rem !important;
}

.u-p-2xs {
  padding: 0.25rem !important;
}

.u-p-xs {
  padding: 0.5rem !important;
}

.u-p-sm {
  padding: 1rem !important;
}

.u-p-md {
  padding: 1.5rem !important;
}

.u-p-lg {
  padding: 2rem !important;
}

.u-p-xl {
  padding: 3.5rem !important;
}

.u-p-2xl {
  padding: 6rem !important;
}

.u-p-3xl {
  padding: 9rem !important;
}

.u-pt-none {
  padding-top: 0 !important;
}

.u-pt-3xs {
  padding-top: 0.125rem !important;
}

.u-pt-2xs {
  padding-top: 0.25rem !important;
}

.u-pt-xs {
  padding-top: 0.5rem !important;
}

.u-pt-sm {
  padding-top: 1rem !important;
}

.u-pt-md {
  padding-top: 1.5rem !important;
}

.u-pt-lg {
  padding-top: 2rem !important;
}

.u-pt-xl {
  padding-top: 3.5rem !important;
}

.u-pt-2xl {
  padding-top: 6rem !important;
}

.u-pt-3xl {
  padding-top: 9rem !important;
}

.u-pr-none {
  padding-right: 0 !important;
}

.u-pr-3xs {
  padding-right: 0.125rem !important;
}

.u-pr-2xs {
  padding-right: 0.25rem !important;
}

.u-pr-xs {
  padding-right: 0.5rem !important;
}

.u-pr-sm {
  padding-right: 1rem !important;
}

.u-pr-md {
  padding-right: 1.5rem !important;
}

.u-pr-lg {
  padding-right: 2rem !important;
}

.u-pr-xl {
  padding-right: 3.5rem !important;
}

.u-pr-2xl {
  padding-right: 6rem !important;
}

.u-pr-3xl {
  padding-right: 9rem !important;
}

.u-pb-none {
  padding-bottom: 0 !important;
}

.u-pb-3xs {
  padding-bottom: 0.125rem !important;
}

.u-pb-2xs {
  padding-bottom: 0.25rem !important;
}

.u-pb-xs {
  padding-bottom: 0.5rem !important;
}

.u-pb-sm {
  padding-bottom: 1rem !important;
}

.u-pb-md {
  padding-bottom: 1.5rem !important;
}

.u-pb-lg {
  padding-bottom: 2rem !important;
}

.u-pb-xl {
  padding-bottom: 3.5rem !important;
}

.u-pb-2xl {
  padding-bottom: 6rem !important;
}

.u-pb-3xl {
  padding-bottom: 9rem !important;
}

.u-pl-none {
  padding-left: 0 !important;
}

.u-pl-3xs {
  padding-left: 0.125rem !important;
}

.u-pl-2xs {
  padding-left: 0.25rem !important;
}

.u-pl-xs {
  padding-left: 0.5rem !important;
}

.u-pl-sm {
  padding-left: 1rem !important;
}

.u-pl-md {
  padding-left: 1.5rem !important;
}

.u-pl-lg {
  padding-left: 2rem !important;
}

.u-pl-xl {
  padding-left: 3.5rem !important;
}

.u-pl-2xl {
  padding-left: 6rem !important;
}

.u-pl-3xl {
  padding-left: 9rem !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-p-none {
    padding: 0 !important;
  }
  .md\:u-p-3xs {
    padding: 0.125rem !important;
  }
  .md\:u-p-2xs {
    padding: 0.25rem !important;
  }
  .md\:u-p-xs {
    padding: 0.5rem !important;
  }
  .md\:u-p-sm {
    padding: 1rem !important;
  }
  .md\:u-p-md {
    padding: 1.5rem !important;
  }
  .md\:u-p-lg {
    padding: 2rem !important;
  }
  .md\:u-p-xl {
    padding: 3.5rem !important;
  }
  .md\:u-p-2xl {
    padding: 6rem !important;
  }
  .md\:u-p-3xl {
    padding: 9rem !important;
  }
  .md\:u-pt-none {
    padding-top: 0 !important;
  }
  .md\:u-pt-3xs {
    padding-top: 0.125rem !important;
  }
  .md\:u-pt-2xs {
    padding-top: 0.25rem !important;
  }
  .md\:u-pt-xs {
    padding-top: 0.5rem !important;
  }
  .md\:u-pt-sm {
    padding-top: 1rem !important;
  }
  .md\:u-pt-md {
    padding-top: 1.5rem !important;
  }
  .md\:u-pt-lg {
    padding-top: 2rem !important;
  }
  .md\:u-pt-xl {
    padding-top: 3.5rem !important;
  }
  .md\:u-pt-2xl {
    padding-top: 6rem !important;
  }
  .md\:u-pt-3xl {
    padding-top: 9rem !important;
  }
  .md\:u-pr-none {
    padding-right: 0 !important;
  }
  .md\:u-pr-3xs {
    padding-right: 0.125rem !important;
  }
  .md\:u-pr-2xs {
    padding-right: 0.25rem !important;
  }
  .md\:u-pr-xs {
    padding-right: 0.5rem !important;
  }
  .md\:u-pr-sm {
    padding-right: 1rem !important;
  }
  .md\:u-pr-md {
    padding-right: 1.5rem !important;
  }
  .md\:u-pr-lg {
    padding-right: 2rem !important;
  }
  .md\:u-pr-xl {
    padding-right: 3.5rem !important;
  }
  .md\:u-pr-2xl {
    padding-right: 6rem !important;
  }
  .md\:u-pr-3xl {
    padding-right: 9rem !important;
  }
  .md\:u-pb-none {
    padding-bottom: 0 !important;
  }
  .md\:u-pb-3xs {
    padding-bottom: 0.125rem !important;
  }
  .md\:u-pb-2xs {
    padding-bottom: 0.25rem !important;
  }
  .md\:u-pb-xs {
    padding-bottom: 0.5rem !important;
  }
  .md\:u-pb-sm {
    padding-bottom: 1rem !important;
  }
  .md\:u-pb-md {
    padding-bottom: 1.5rem !important;
  }
  .md\:u-pb-lg {
    padding-bottom: 2rem !important;
  }
  .md\:u-pb-xl {
    padding-bottom: 3.5rem !important;
  }
  .md\:u-pb-2xl {
    padding-bottom: 6rem !important;
  }
  .md\:u-pb-3xl {
    padding-bottom: 9rem !important;
  }
  .md\:u-pl-none {
    padding-left: 0 !important;
  }
  .md\:u-pl-3xs {
    padding-left: 0.125rem !important;
  }
  .md\:u-pl-2xs {
    padding-left: 0.25rem !important;
  }
  .md\:u-pl-xs {
    padding-left: 0.5rem !important;
  }
  .md\:u-pl-sm {
    padding-left: 1rem !important;
  }
  .md\:u-pl-md {
    padding-left: 1.5rem !important;
  }
  .md\:u-pl-lg {
    padding-left: 2rem !important;
  }
  .md\:u-pl-xl {
    padding-left: 3.5rem !important;
  }
  .md\:u-pl-2xl {
    padding-left: 6rem !important;
  }
  .md\:u-pl-3xl {
    padding-left: 9rem !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-p-none {
    padding: 0 !important;
  }
  .sm\:u-p-3xs {
    padding: 0.125rem !important;
  }
  .sm\:u-p-2xs {
    padding: 0.25rem !important;
  }
  .sm\:u-p-xs {
    padding: 0.5rem !important;
  }
  .sm\:u-p-sm {
    padding: 1rem !important;
  }
  .sm\:u-p-md {
    padding: 1.5rem !important;
  }
  .sm\:u-p-lg {
    padding: 2rem !important;
  }
  .sm\:u-p-xl {
    padding: 3.5rem !important;
  }
  .sm\:u-p-2xl {
    padding: 6rem !important;
  }
  .sm\:u-p-3xl {
    padding: 9rem !important;
  }
  .sm\:u-pt-none {
    padding-top: 0 !important;
  }
  .sm\:u-pt-3xs {
    padding-top: 0.125rem !important;
  }
  .sm\:u-pt-2xs {
    padding-top: 0.25rem !important;
  }
  .sm\:u-pt-xs {
    padding-top: 0.5rem !important;
  }
  .sm\:u-pt-sm {
    padding-top: 1rem !important;
  }
  .sm\:u-pt-md {
    padding-top: 1.5rem !important;
  }
  .sm\:u-pt-lg {
    padding-top: 2rem !important;
  }
  .sm\:u-pt-xl {
    padding-top: 3.5rem !important;
  }
  .sm\:u-pt-2xl {
    padding-top: 6rem !important;
  }
  .sm\:u-pt-3xl {
    padding-top: 9rem !important;
  }
  .sm\:u-pr-none {
    padding-right: 0 !important;
  }
  .sm\:u-pr-3xs {
    padding-right: 0.125rem !important;
  }
  .sm\:u-pr-2xs {
    padding-right: 0.25rem !important;
  }
  .sm\:u-pr-xs {
    padding-right: 0.5rem !important;
  }
  .sm\:u-pr-sm {
    padding-right: 1rem !important;
  }
  .sm\:u-pr-md {
    padding-right: 1.5rem !important;
  }
  .sm\:u-pr-lg {
    padding-right: 2rem !important;
  }
  .sm\:u-pr-xl {
    padding-right: 3.5rem !important;
  }
  .sm\:u-pr-2xl {
    padding-right: 6rem !important;
  }
  .sm\:u-pr-3xl {
    padding-right: 9rem !important;
  }
  .sm\:u-pb-none {
    padding-bottom: 0 !important;
  }
  .sm\:u-pb-3xs {
    padding-bottom: 0.125rem !important;
  }
  .sm\:u-pb-2xs {
    padding-bottom: 0.25rem !important;
  }
  .sm\:u-pb-xs {
    padding-bottom: 0.5rem !important;
  }
  .sm\:u-pb-sm {
    padding-bottom: 1rem !important;
  }
  .sm\:u-pb-md {
    padding-bottom: 1.5rem !important;
  }
  .sm\:u-pb-lg {
    padding-bottom: 2rem !important;
  }
  .sm\:u-pb-xl {
    padding-bottom: 3.5rem !important;
  }
  .sm\:u-pb-2xl {
    padding-bottom: 6rem !important;
  }
  .sm\:u-pb-3xl {
    padding-bottom: 9rem !important;
  }
  .sm\:u-pl-none {
    padding-left: 0 !important;
  }
  .sm\:u-pl-3xs {
    padding-left: 0.125rem !important;
  }
  .sm\:u-pl-2xs {
    padding-left: 0.25rem !important;
  }
  .sm\:u-pl-xs {
    padding-left: 0.5rem !important;
  }
  .sm\:u-pl-sm {
    padding-left: 1rem !important;
  }
  .sm\:u-pl-md {
    padding-left: 1.5rem !important;
  }
  .sm\:u-pl-lg {
    padding-left: 2rem !important;
  }
  .sm\:u-pl-xl {
    padding-left: 3.5rem !important;
  }
  .sm\:u-pl-2xl {
    padding-left: 6rem !important;
  }
  .sm\:u-pl-3xl {
    padding-left: 9rem !important;
  }
}
.u-tracking-none {
  letter-spacing: 0 !important;
}

.u-tracking-sm {
  letter-spacing: 0.025em !important;
}

.u-tracking-normal {
  letter-spacing: 0.1em !important;
}

.u-tracking-lg {
  letter-spacing: 0.125em !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-tracking-none {
    letter-spacing: 0 !important;
  }
  .md\:u-tracking-sm {
    letter-spacing: 0.025em !important;
  }
  .md\:u-tracking-normal {
    letter-spacing: 0.1em !important;
  }
  .md\:u-tracking-lg {
    letter-spacing: 0.125em !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-tracking-none {
    letter-spacing: 0 !important;
  }
  .sm\:u-tracking-sm {
    letter-spacing: 0.025em !important;
  }
  .sm\:u-tracking-normal {
    letter-spacing: 0.1em !important;
  }
  .sm\:u-tracking-lg {
    letter-spacing: 0.125em !important;
  }
}
.u-flex-row {
  flex-direction: row !important;
}

.u-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.u-flex-col {
  flex-direction: column !important;
}

.u-flex-col-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-flex-row {
    flex-direction: row !important;
  }
  .md\:u-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md\:u-flex-col {
    flex-direction: column !important;
  }
  .md\:u-flex-col-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-flex-row {
    flex-direction: row !important;
  }
  .sm\:u-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm\:u-flex-col {
    flex-direction: column !important;
  }
  .sm\:u-flex-col-reverse {
    flex-direction: column-reverse !important;
  }
}
.u-flex-grow-0 {
  flex-grow: 0 !important;
}

.u-flex-grow {
  flex-grow: 1 !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md\:u-flex-grow {
    flex-grow: 1 !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm\:u-flex-grow {
    flex-grow: 1 !important;
  }
}
.u-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.u-flex-shrink {
  flex-shrink: 1 !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md\:u-flex-shrink {
    flex-shrink: 1 !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm\:u-flex-shrink {
    flex-shrink: 1 !important;
  }
}
.u-flex-wrap {
  flex-wrap: wrap !important;
}

.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.u-flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-flex-wrap {
    flex-wrap: wrap !important;
  }
  .md\:u-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md\:u-flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm\:u-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .sm\:u-flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
.u-flex-1 {
  flex: 1 1 0% !important;
}

.u-flex-auto {
  flex: 1 1 auto !important;
}

.u-flex-initial {
  flex: 0 1 auto !important;
}

.u-flex-none {
  flex: none !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-flex-1 {
    flex: 1 1 0% !important;
  }
  .md\:u-flex-auto {
    flex: 1 1 auto !important;
  }
  .md\:u-flex-initial {
    flex: 0 1 auto !important;
  }
  .md\:u-flex-none {
    flex: none !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-flex-1 {
    flex: 1 1 0% !important;
  }
  .sm\:u-flex-auto {
    flex: 1 1 auto !important;
  }
  .sm\:u-flex-initial {
    flex: 0 1 auto !important;
  }
  .sm\:u-flex-none {
    flex: none !important;
  }
}
.u-flex-order-1 {
  order: 1 !important;
}

.u-flex-order-2 {
  order: 2 !important;
}

.u-flex-order-3 {
  order: 3 !important;
}

.u-flex-order-4 {
  order: 4 !important;
}

.u-flex-order-5 {
  order: 5 !important;
}

.u-flex-order-6 {
  order: 6 !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-flex-order-1 {
    order: 1 !important;
  }
  .md\:u-flex-order-2 {
    order: 2 !important;
  }
  .md\:u-flex-order-3 {
    order: 3 !important;
  }
  .md\:u-flex-order-4 {
    order: 4 !important;
  }
  .md\:u-flex-order-5 {
    order: 5 !important;
  }
  .md\:u-flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-flex-order-1 {
    order: 1 !important;
  }
  .sm\:u-flex-order-2 {
    order: 2 !important;
  }
  .sm\:u-flex-order-3 {
    order: 3 !important;
  }
  .sm\:u-flex-order-4 {
    order: 4 !important;
  }
  .sm\:u-flex-order-5 {
    order: 5 !important;
  }
  .sm\:u-flex-order-6 {
    order: 6 !important;
  }
}
.u-cursor-auto {
  cursor: auto !important;
}

.u-cursor-default {
  cursor: default !important;
}

.u-cursor-pointer {
  cursor: pointer !important;
}

.u-cursor-wait {
  cursor: wait !important;
}

.u-cursor-text {
  cursor: text !important;
}

.u-cursor-move {
  cursor: move !important;
}

.u-cursor-not-allowed {
  cursor: not-allowed !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-cursor-auto {
    cursor: auto !important;
  }
  .md\:u-cursor-default {
    cursor: default !important;
  }
  .md\:u-cursor-pointer {
    cursor: pointer !important;
  }
  .md\:u-cursor-wait {
    cursor: wait !important;
  }
  .md\:u-cursor-text {
    cursor: text !important;
  }
  .md\:u-cursor-move {
    cursor: move !important;
  }
  .md\:u-cursor-not-allowed {
    cursor: not-allowed !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-cursor-auto {
    cursor: auto !important;
  }
  .sm\:u-cursor-default {
    cursor: default !important;
  }
  .sm\:u-cursor-pointer {
    cursor: pointer !important;
  }
  .sm\:u-cursor-wait {
    cursor: wait !important;
  }
  .sm\:u-cursor-text {
    cursor: text !important;
  }
  .sm\:u-cursor-move {
    cursor: move !important;
  }
  .sm\:u-cursor-not-allowed {
    cursor: not-allowed !important;
  }
}
.u-pointer-events-none {
  pointer-events: none !important;
}

.u-pointer-events-auto {
  pointer-events: auto !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-pointer-events-none {
    pointer-events: none !important;
  }
  .md\:u-pointer-events-auto {
    pointer-events: auto !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-pointer-events-none {
    pointer-events: none !important;
  }
  .sm\:u-pointer-events-auto {
    pointer-events: auto !important;
  }
}
.u-block {
  display: block !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-inline {
  display: inline !important;
}

.u-flex {
  display: flex !important;
}

.u-inline-flex {
  display: inline-flex !important;
}

.u-none {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-block {
    display: block !important;
  }
  .md\:u-inline-block {
    display: inline-block !important;
  }
  .md\:u-inline {
    display: inline !important;
  }
  .md\:u-flex {
    display: flex !important;
  }
  .md\:u-inline-flex {
    display: inline-flex !important;
  }
  .md\:u-none {
    display: none !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-block {
    display: block !important;
  }
  .sm\:u-inline-block {
    display: inline-block !important;
  }
  .sm\:u-inline {
    display: inline !important;
  }
  .sm\:u-flex {
    display: flex !important;
  }
  .sm\:u-inline-flex {
    display: inline-flex !important;
  }
  .sm\:u-none {
    display: none !important;
  }
}
.u-overflow-auto {
  overflow: auto !important;
}

.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-visible {
  overflow: visible !important;
}

.u-overflow-scroll {
  overflow: scroll !important;
}

.u-overflow-x-scroll {
  overflow-x: scroll !important;
}

.u-overflow-y-scroll {
  overflow-y: scroll !important;
}

.u-overflow-x-auto {
  overflow-x: auto !important;
}

.u-overflow-y-auto {
  overflow-y: auto !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-overflow-auto {
    overflow: auto !important;
  }
  .md\:u-overflow-hidden {
    overflow: hidden !important;
  }
  .md\:u-overflow-visible {
    overflow: visible !important;
  }
  .md\:u-overflow-scroll {
    overflow: scroll !important;
  }
  .md\:u-overflow-x-scroll {
    overflow-x: scroll !important;
  }
  .md\:u-overflow-y-scroll {
    overflow-y: scroll !important;
  }
  .md\:u-overflow-x-auto {
    overflow-x: auto !important;
  }
  .md\:u-overflow-y-auto {
    overflow-y: auto !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-overflow-auto {
    overflow: auto !important;
  }
  .sm\:u-overflow-hidden {
    overflow: hidden !important;
  }
  .sm\:u-overflow-visible {
    overflow: visible !important;
  }
  .sm\:u-overflow-scroll {
    overflow: scroll !important;
  }
  .sm\:u-overflow-x-scroll {
    overflow-x: scroll !important;
  }
  .sm\:u-overflow-y-scroll {
    overflow-y: scroll !important;
  }
  .sm\:u-overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm\:u-overflow-y-auto {
    overflow-y: auto !important;
  }
}
.u-static {
  position: static !important;
}

.u-fixed {
  position: fixed !important;
}

.u-absolute {
  position: absolute !important;
}

.u-relative {
  position: relative !important;
}

.u-sticky {
  position: sticky !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-static {
    position: static !important;
  }
  .md\:u-fixed {
    position: fixed !important;
  }
  .md\:u-absolute {
    position: absolute !important;
  }
  .md\:u-relative {
    position: relative !important;
  }
  .md\:u-sticky {
    position: sticky !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-static {
    position: static !important;
  }
  .sm\:u-fixed {
    position: fixed !important;
  }
  .sm\:u-absolute {
    position: absolute !important;
  }
  .sm\:u-relative {
    position: relative !important;
  }
  .sm\:u-sticky {
    position: sticky !important;
  }
}
.u-top-0 {
  top: 0 !important;
}

.u-top-auto {
  top: auto !important;
}

.u-top-1\/12 {
  top: 8.333333% !important;
}

.u-top-2\/12 {
  top: 16.666667% !important;
}

.u-top-3\/12 {
  top: 25% !important;
}

.u-top-4\/12 {
  top: 33.333333% !important;
}

.u-top-5\/12 {
  top: 41.666667% !important;
}

.u-top-6\/12 {
  top: 50% !important;
}

.u-top-7\/12 {
  top: 58.333333% !important;
}

.u-top-8\/12 {
  top: 66.666667% !important;
}

.u-top-9\/12 {
  top: 75% !important;
}

.u-top-10\/12 {
  top: 83.333333% !important;
}

.u-top-11\/12 {
  top: 91.666667% !important;
}

.u-top-12\/12 {
  top: 100% !important;
}

.u-top-neg-1\/12 {
  top: -8.333333% !important;
}

.u-top-neg-2\/12 {
  top: -16.666667% !important;
}

.u-top-neg-3\/12 {
  top: -25% !important;
}

.u-top-neg-4\/12 {
  top: -33.333333% !important;
}

.u-top-neg-5\/12 {
  top: -41.666667% !important;
}

.u-top-neg-6\/12 {
  top: -50% !important;
}

.u-top-neg-7\/12 {
  top: -58.333333% !important;
}

.u-top-neg-8\/12 {
  top: -66.666667% !important;
}

.u-top-neg-9\/12 {
  top: -75% !important;
}

.u-top-neg-10\/12 {
  top: -83.333333% !important;
}

.u-top-neg-11\/12 {
  top: -91.666667% !important;
}

.u-top-neg-12\/12 {
  top: -100% !important;
}

.u-right-auto {
  right: auto !important;
}

.u-right-0 {
  right: 0 !important;
}

.u-right-1\/12 {
  right: 8.333333% !important;
}

.u-right-2\/12 {
  right: 16.666667% !important;
}

.u-right-3\/12 {
  right: 25% !important;
}

.u-right-4\/12 {
  right: 33.333333% !important;
}

.u-right-5\/12 {
  right: 41.666667% !important;
}

.u-right-6\/12 {
  right: 50% !important;
}

.u-right-7\/12 {
  right: 58.333333% !important;
}

.u-right-8\/12 {
  right: 66.666667% !important;
}

.u-right-9\/12 {
  right: 75% !important;
}

.u-right-10\/12 {
  right: 83.333333% !important;
}

.u-right-11\/12 {
  right: 91.666667% !important;
}

.u-right-12\/12 {
  right: 100% !important;
}

.u-right-neg-1\/12 {
  right: -8.333333% !important;
}

.u-right-neg-2\/12 {
  right: -16.666667% !important;
}

.u-right-neg-3\/12 {
  right: -25% !important;
}

.u-right-neg-4\/12 {
  right: -33.333333% !important;
}

.u-right-neg-5\/12 {
  right: -41.666667% !important;
}

.u-right-neg-6\/12 {
  right: -50% !important;
}

.u-right-neg-7\/12 {
  right: -58.333333% !important;
}

.u-right-neg-8\/12 {
  right: -66.666667% !important;
}

.u-right-neg-9\/12 {
  right: -75% !important;
}

.u-right-neg-10\/12 {
  right: -83.333333% !important;
}

.u-right-neg-11\/12 {
  right: -91.666667% !important;
}

.u-right-neg-12\/12 {
  right: -100% !important;
}

.u-bottom-auto {
  bottom: auto !important;
}

.u-bottom-0 {
  bottom: 0 !important;
}

.u-bottom-1\/12 {
  bottom: 8.333333% !important;
}

.u-bottom-2\/12 {
  bottom: 16.666667% !important;
}

.u-bottom-3\/12 {
  bottom: 25% !important;
}

.u-bottom-4\/12 {
  bottom: 33.333333% !important;
}

.u-bottom-5\/12 {
  bottom: 41.666667% !important;
}

.u-bottom-6\/12 {
  bottom: 50% !important;
}

.u-bottom-7\/12 {
  bottom: 58.333333% !important;
}

.u-bottom-8\/12 {
  bottom: 66.666667% !important;
}

.u-bottom-9\/12 {
  bottom: 75% !important;
}

.u-bottom-10\/12 {
  bottom: 83.333333% !important;
}

.u-bottom-11\/12 {
  bottom: 91.666667% !important;
}

.u-bottom-12\/12 {
  bottom: 100% !important;
}

.u-bottom-neg-1\/12 {
  bottom: -8.333333% !important;
}

.u-bottom-neg-2\/12 {
  bottom: -16.666667% !important;
}

.u-bottom-neg-3\/12 {
  bottom: -25% !important;
}

.u-bottom-neg-4\/12 {
  bottom: -33.333333% !important;
}

.u-bottom-neg-5\/12 {
  bottom: -41.666667% !important;
}

.u-bottom-neg-6\/12 {
  bottom: -50% !important;
}

.u-bottom-neg-7\/12 {
  bottom: -58.333333% !important;
}

.u-bottom-neg-8\/12 {
  bottom: -66.666667% !important;
}

.u-bottom-neg-9\/12 {
  bottom: -75% !important;
}

.u-bottom-neg-10\/12 {
  bottom: -83.333333% !important;
}

.u-bottom-neg-11\/12 {
  bottom: -91.666667% !important;
}

.u-bottom-neg-12\/12 {
  bottom: -100% !important;
}

.u-left-auto {
  left: auto !important;
}

.u-left-0 {
  left: 0 !important;
}

.u-left-1\/12 {
  left: 8.333333% !important;
}

.u-left-2\/12 {
  left: 16.666667% !important;
}

.u-left-3\/12 {
  left: 25% !important;
}

.u-left-4\/12 {
  left: 33.333333% !important;
}

.u-left-5\/12 {
  left: 41.666667% !important;
}

.u-left-6\/12 {
  left: 50% !important;
}

.u-left-7\/12 {
  left: 58.333333% !important;
}

.u-left-8\/12 {
  left: 66.666667% !important;
}

.u-left-9\/12 {
  left: 75% !important;
}

.u-left-10\/12 {
  left: 83.333333% !important;
}

.u-left-11\/12 {
  left: 91.666667% !important;
}

.u-left-12\/12 {
  left: 100% !important;
}

.u-left-neg-1\/12 {
  left: -8.333333% !important;
}

.u-left-neg-2\/12 {
  left: -16.666667% !important;
}

.u-left-neg-3\/12 {
  left: -25% !important;
}

.u-left-neg-4\/12 {
  left: -33.333333% !important;
}

.u-left-neg-5\/12 {
  left: -41.666667% !important;
}

.u-left-neg-6\/12 {
  left: -50% !important;
}

.u-left-neg-7\/12 {
  left: -58.333333% !important;
}

.u-left-neg-8\/12 {
  left: -66.666667% !important;
}

.u-left-neg-9\/12 {
  left: -75% !important;
}

.u-left-neg-10\/12 {
  left: -83.333333% !important;
}

.u-left-neg-11\/12 {
  left: -91.666667% !important;
}

.u-left-neg-12\/12 {
  left: -100% !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-top-0 {
    top: 0 !important;
  }
  .md\:u-top-auto {
    top: auto !important;
  }
  .md\:u-top-1\/12 {
    top: 8.333333% !important;
  }
  .md\:u-top-2\/12 {
    top: 16.666667% !important;
  }
  .md\:u-top-3\/12 {
    top: 25% !important;
  }
  .md\:u-top-4\/12 {
    top: 33.333333% !important;
  }
  .md\:u-top-5\/12 {
    top: 41.666667% !important;
  }
  .md\:u-top-6\/12 {
    top: 50% !important;
  }
  .md\:u-top-7\/12 {
    top: 58.333333% !important;
  }
  .md\:u-top-8\/12 {
    top: 66.666667% !important;
  }
  .md\:u-top-9\/12 {
    top: 75% !important;
  }
  .md\:u-top-10\/12 {
    top: 83.333333% !important;
  }
  .md\:u-top-11\/12 {
    top: 91.666667% !important;
  }
  .md\:u-top-12\/12 {
    top: 100% !important;
  }
  .md\:u-top-neg-1\/12 {
    top: -8.333333% !important;
  }
  .md\:u-top-neg-2\/12 {
    top: -16.666667% !important;
  }
  .md\:u-top-neg-3\/12 {
    top: -25% !important;
  }
  .md\:u-top-neg-4\/12 {
    top: -33.333333% !important;
  }
  .md\:u-top-neg-5\/12 {
    top: -41.666667% !important;
  }
  .md\:u-top-neg-6\/12 {
    top: -50% !important;
  }
  .md\:u-top-neg-7\/12 {
    top: -58.333333% !important;
  }
  .md\:u-top-neg-8\/12 {
    top: -66.666667% !important;
  }
  .md\:u-top-neg-9\/12 {
    top: -75% !important;
  }
  .md\:u-top-neg-10\/12 {
    top: -83.333333% !important;
  }
  .md\:u-top-neg-11\/12 {
    top: -91.666667% !important;
  }
  .md\:u-top-neg-12\/12 {
    top: -100% !important;
  }
  .md\:u-right-auto {
    right: auto !important;
  }
  .md\:u-right-0 {
    right: 0 !important;
  }
  .md\:u-right-1\/12 {
    right: 8.333333% !important;
  }
  .md\:u-right-2\/12 {
    right: 16.666667% !important;
  }
  .md\:u-right-3\/12 {
    right: 25% !important;
  }
  .md\:u-right-4\/12 {
    right: 33.333333% !important;
  }
  .md\:u-right-5\/12 {
    right: 41.666667% !important;
  }
  .md\:u-right-6\/12 {
    right: 50% !important;
  }
  .md\:u-right-7\/12 {
    right: 58.333333% !important;
  }
  .md\:u-right-8\/12 {
    right: 66.666667% !important;
  }
  .md\:u-right-9\/12 {
    right: 75% !important;
  }
  .md\:u-right-10\/12 {
    right: 83.333333% !important;
  }
  .md\:u-right-11\/12 {
    right: 91.666667% !important;
  }
  .md\:u-right-12\/12 {
    right: 100% !important;
  }
  .md\:u-right-neg-1\/12 {
    right: -8.333333% !important;
  }
  .md\:u-right-neg-2\/12 {
    right: -16.666667% !important;
  }
  .md\:u-right-neg-3\/12 {
    right: -25% !important;
  }
  .md\:u-right-neg-4\/12 {
    right: -33.333333% !important;
  }
  .md\:u-right-neg-5\/12 {
    right: -41.666667% !important;
  }
  .md\:u-right-neg-6\/12 {
    right: -50% !important;
  }
  .md\:u-right-neg-7\/12 {
    right: -58.333333% !important;
  }
  .md\:u-right-neg-8\/12 {
    right: -66.666667% !important;
  }
  .md\:u-right-neg-9\/12 {
    right: -75% !important;
  }
  .md\:u-right-neg-10\/12 {
    right: -83.333333% !important;
  }
  .md\:u-right-neg-11\/12 {
    right: -91.666667% !important;
  }
  .md\:u-right-neg-12\/12 {
    right: -100% !important;
  }
  .md\:u-bottom-auto {
    bottom: auto !important;
  }
  .md\:u-bottom-0 {
    bottom: 0 !important;
  }
  .md\:u-bottom-1\/12 {
    bottom: 8.333333% !important;
  }
  .md\:u-bottom-2\/12 {
    bottom: 16.666667% !important;
  }
  .md\:u-bottom-3\/12 {
    bottom: 25% !important;
  }
  .md\:u-bottom-4\/12 {
    bottom: 33.333333% !important;
  }
  .md\:u-bottom-5\/12 {
    bottom: 41.666667% !important;
  }
  .md\:u-bottom-6\/12 {
    bottom: 50% !important;
  }
  .md\:u-bottom-7\/12 {
    bottom: 58.333333% !important;
  }
  .md\:u-bottom-8\/12 {
    bottom: 66.666667% !important;
  }
  .md\:u-bottom-9\/12 {
    bottom: 75% !important;
  }
  .md\:u-bottom-10\/12 {
    bottom: 83.333333% !important;
  }
  .md\:u-bottom-11\/12 {
    bottom: 91.666667% !important;
  }
  .md\:u-bottom-12\/12 {
    bottom: 100% !important;
  }
  .md\:u-bottom-neg-1\/12 {
    bottom: -8.333333% !important;
  }
  .md\:u-bottom-neg-2\/12 {
    bottom: -16.666667% !important;
  }
  .md\:u-bottom-neg-3\/12 {
    bottom: -25% !important;
  }
  .md\:u-bottom-neg-4\/12 {
    bottom: -33.333333% !important;
  }
  .md\:u-bottom-neg-5\/12 {
    bottom: -41.666667% !important;
  }
  .md\:u-bottom-neg-6\/12 {
    bottom: -50% !important;
  }
  .md\:u-bottom-neg-7\/12 {
    bottom: -58.333333% !important;
  }
  .md\:u-bottom-neg-8\/12 {
    bottom: -66.666667% !important;
  }
  .md\:u-bottom-neg-9\/12 {
    bottom: -75% !important;
  }
  .md\:u-bottom-neg-10\/12 {
    bottom: -83.333333% !important;
  }
  .md\:u-bottom-neg-11\/12 {
    bottom: -91.666667% !important;
  }
  .md\:u-bottom-neg-12\/12 {
    bottom: -100% !important;
  }
  .md\:u-left-auto {
    left: auto !important;
  }
  .md\:u-left-0 {
    left: 0 !important;
  }
  .md\:u-left-1\/12 {
    left: 8.333333% !important;
  }
  .md\:u-left-2\/12 {
    left: 16.666667% !important;
  }
  .md\:u-left-3\/12 {
    left: 25% !important;
  }
  .md\:u-left-4\/12 {
    left: 33.333333% !important;
  }
  .md\:u-left-5\/12 {
    left: 41.666667% !important;
  }
  .md\:u-left-6\/12 {
    left: 50% !important;
  }
  .md\:u-left-7\/12 {
    left: 58.333333% !important;
  }
  .md\:u-left-8\/12 {
    left: 66.666667% !important;
  }
  .md\:u-left-9\/12 {
    left: 75% !important;
  }
  .md\:u-left-10\/12 {
    left: 83.333333% !important;
  }
  .md\:u-left-11\/12 {
    left: 91.666667% !important;
  }
  .md\:u-left-12\/12 {
    left: 100% !important;
  }
  .md\:u-left-neg-1\/12 {
    left: -8.333333% !important;
  }
  .md\:u-left-neg-2\/12 {
    left: -16.666667% !important;
  }
  .md\:u-left-neg-3\/12 {
    left: -25% !important;
  }
  .md\:u-left-neg-4\/12 {
    left: -33.333333% !important;
  }
  .md\:u-left-neg-5\/12 {
    left: -41.666667% !important;
  }
  .md\:u-left-neg-6\/12 {
    left: -50% !important;
  }
  .md\:u-left-neg-7\/12 {
    left: -58.333333% !important;
  }
  .md\:u-left-neg-8\/12 {
    left: -66.666667% !important;
  }
  .md\:u-left-neg-9\/12 {
    left: -75% !important;
  }
  .md\:u-left-neg-10\/12 {
    left: -83.333333% !important;
  }
  .md\:u-left-neg-11\/12 {
    left: -91.666667% !important;
  }
  .md\:u-left-neg-12\/12 {
    left: -100% !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-top-0 {
    top: 0 !important;
  }
  .sm\:u-top-auto {
    top: auto !important;
  }
  .sm\:u-top-1\/12 {
    top: 8.333333% !important;
  }
  .sm\:u-top-2\/12 {
    top: 16.666667% !important;
  }
  .sm\:u-top-3\/12 {
    top: 25% !important;
  }
  .sm\:u-top-4\/12 {
    top: 33.333333% !important;
  }
  .sm\:u-top-5\/12 {
    top: 41.666667% !important;
  }
  .sm\:u-top-6\/12 {
    top: 50% !important;
  }
  .sm\:u-top-7\/12 {
    top: 58.333333% !important;
  }
  .sm\:u-top-8\/12 {
    top: 66.666667% !important;
  }
  .sm\:u-top-9\/12 {
    top: 75% !important;
  }
  .sm\:u-top-10\/12 {
    top: 83.333333% !important;
  }
  .sm\:u-top-11\/12 {
    top: 91.666667% !important;
  }
  .sm\:u-top-12\/12 {
    top: 100% !important;
  }
  .sm\:u-top-neg-1\/12 {
    top: -8.333333% !important;
  }
  .sm\:u-top-neg-2\/12 {
    top: -16.666667% !important;
  }
  .sm\:u-top-neg-3\/12 {
    top: -25% !important;
  }
  .sm\:u-top-neg-4\/12 {
    top: -33.333333% !important;
  }
  .sm\:u-top-neg-5\/12 {
    top: -41.666667% !important;
  }
  .sm\:u-top-neg-6\/12 {
    top: -50% !important;
  }
  .sm\:u-top-neg-7\/12 {
    top: -58.333333% !important;
  }
  .sm\:u-top-neg-8\/12 {
    top: -66.666667% !important;
  }
  .sm\:u-top-neg-9\/12 {
    top: -75% !important;
  }
  .sm\:u-top-neg-10\/12 {
    top: -83.333333% !important;
  }
  .sm\:u-top-neg-11\/12 {
    top: -91.666667% !important;
  }
  .sm\:u-top-neg-12\/12 {
    top: -100% !important;
  }
  .sm\:u-right-auto {
    right: auto !important;
  }
  .sm\:u-right-0 {
    right: 0 !important;
  }
  .sm\:u-right-1\/12 {
    right: 8.333333% !important;
  }
  .sm\:u-right-2\/12 {
    right: 16.666667% !important;
  }
  .sm\:u-right-3\/12 {
    right: 25% !important;
  }
  .sm\:u-right-4\/12 {
    right: 33.333333% !important;
  }
  .sm\:u-right-5\/12 {
    right: 41.666667% !important;
  }
  .sm\:u-right-6\/12 {
    right: 50% !important;
  }
  .sm\:u-right-7\/12 {
    right: 58.333333% !important;
  }
  .sm\:u-right-8\/12 {
    right: 66.666667% !important;
  }
  .sm\:u-right-9\/12 {
    right: 75% !important;
  }
  .sm\:u-right-10\/12 {
    right: 83.333333% !important;
  }
  .sm\:u-right-11\/12 {
    right: 91.666667% !important;
  }
  .sm\:u-right-12\/12 {
    right: 100% !important;
  }
  .sm\:u-right-neg-1\/12 {
    right: -8.333333% !important;
  }
  .sm\:u-right-neg-2\/12 {
    right: -16.666667% !important;
  }
  .sm\:u-right-neg-3\/12 {
    right: -25% !important;
  }
  .sm\:u-right-neg-4\/12 {
    right: -33.333333% !important;
  }
  .sm\:u-right-neg-5\/12 {
    right: -41.666667% !important;
  }
  .sm\:u-right-neg-6\/12 {
    right: -50% !important;
  }
  .sm\:u-right-neg-7\/12 {
    right: -58.333333% !important;
  }
  .sm\:u-right-neg-8\/12 {
    right: -66.666667% !important;
  }
  .sm\:u-right-neg-9\/12 {
    right: -75% !important;
  }
  .sm\:u-right-neg-10\/12 {
    right: -83.333333% !important;
  }
  .sm\:u-right-neg-11\/12 {
    right: -91.666667% !important;
  }
  .sm\:u-right-neg-12\/12 {
    right: -100% !important;
  }
  .sm\:u-bottom-auto {
    bottom: auto !important;
  }
  .sm\:u-bottom-0 {
    bottom: 0 !important;
  }
  .sm\:u-bottom-1\/12 {
    bottom: 8.333333% !important;
  }
  .sm\:u-bottom-2\/12 {
    bottom: 16.666667% !important;
  }
  .sm\:u-bottom-3\/12 {
    bottom: 25% !important;
  }
  .sm\:u-bottom-4\/12 {
    bottom: 33.333333% !important;
  }
  .sm\:u-bottom-5\/12 {
    bottom: 41.666667% !important;
  }
  .sm\:u-bottom-6\/12 {
    bottom: 50% !important;
  }
  .sm\:u-bottom-7\/12 {
    bottom: 58.333333% !important;
  }
  .sm\:u-bottom-8\/12 {
    bottom: 66.666667% !important;
  }
  .sm\:u-bottom-9\/12 {
    bottom: 75% !important;
  }
  .sm\:u-bottom-10\/12 {
    bottom: 83.333333% !important;
  }
  .sm\:u-bottom-11\/12 {
    bottom: 91.666667% !important;
  }
  .sm\:u-bottom-12\/12 {
    bottom: 100% !important;
  }
  .sm\:u-bottom-neg-1\/12 {
    bottom: -8.333333% !important;
  }
  .sm\:u-bottom-neg-2\/12 {
    bottom: -16.666667% !important;
  }
  .sm\:u-bottom-neg-3\/12 {
    bottom: -25% !important;
  }
  .sm\:u-bottom-neg-4\/12 {
    bottom: -33.333333% !important;
  }
  .sm\:u-bottom-neg-5\/12 {
    bottom: -41.666667% !important;
  }
  .sm\:u-bottom-neg-6\/12 {
    bottom: -50% !important;
  }
  .sm\:u-bottom-neg-7\/12 {
    bottom: -58.333333% !important;
  }
  .sm\:u-bottom-neg-8\/12 {
    bottom: -66.666667% !important;
  }
  .sm\:u-bottom-neg-9\/12 {
    bottom: -75% !important;
  }
  .sm\:u-bottom-neg-10\/12 {
    bottom: -83.333333% !important;
  }
  .sm\:u-bottom-neg-11\/12 {
    bottom: -91.666667% !important;
  }
  .sm\:u-bottom-neg-12\/12 {
    bottom: -100% !important;
  }
  .sm\:u-left-auto {
    left: auto !important;
  }
  .sm\:u-left-0 {
    left: 0 !important;
  }
  .sm\:u-left-1\/12 {
    left: 8.333333% !important;
  }
  .sm\:u-left-2\/12 {
    left: 16.666667% !important;
  }
  .sm\:u-left-3\/12 {
    left: 25% !important;
  }
  .sm\:u-left-4\/12 {
    left: 33.333333% !important;
  }
  .sm\:u-left-5\/12 {
    left: 41.666667% !important;
  }
  .sm\:u-left-6\/12 {
    left: 50% !important;
  }
  .sm\:u-left-7\/12 {
    left: 58.333333% !important;
  }
  .sm\:u-left-8\/12 {
    left: 66.666667% !important;
  }
  .sm\:u-left-9\/12 {
    left: 75% !important;
  }
  .sm\:u-left-10\/12 {
    left: 83.333333% !important;
  }
  .sm\:u-left-11\/12 {
    left: 91.666667% !important;
  }
  .sm\:u-left-12\/12 {
    left: 100% !important;
  }
  .sm\:u-left-neg-1\/12 {
    left: -8.333333% !important;
  }
  .sm\:u-left-neg-2\/12 {
    left: -16.666667% !important;
  }
  .sm\:u-left-neg-3\/12 {
    left: -25% !important;
  }
  .sm\:u-left-neg-4\/12 {
    left: -33.333333% !important;
  }
  .sm\:u-left-neg-5\/12 {
    left: -41.666667% !important;
  }
  .sm\:u-left-neg-6\/12 {
    left: -50% !important;
  }
  .sm\:u-left-neg-7\/12 {
    left: -58.333333% !important;
  }
  .sm\:u-left-neg-8\/12 {
    left: -66.666667% !important;
  }
  .sm\:u-left-neg-9\/12 {
    left: -75% !important;
  }
  .sm\:u-left-neg-10\/12 {
    left: -83.333333% !important;
  }
  .sm\:u-left-neg-11\/12 {
    left: -91.666667% !important;
  }
  .sm\:u-left-neg-12\/12 {
    left: -100% !important;
  }
}
.u-w-auto {
  width: auto !important;
}

.u-w-full {
  width: 100% !important;
}

.u-w-screen {
  width: 100vw !important;
}

.u-w-0 {
  width: 0 !important;
}

.u-w-1\/12 {
  width: 8.333333% !important;
}

.u-w-2\/12 {
  width: 16.666667% !important;
}

.u-w-3\/12 {
  width: 25% !important;
}

.u-w-4\/12 {
  width: 33.333333% !important;
}

.u-w-5\/12 {
  width: 41.666667% !important;
}

.u-w-6\/12 {
  width: 50% !important;
}

.u-w-7\/12 {
  width: 58.333333% !important;
}

.u-w-8\/12 {
  width: 66.666667% !important;
}

.u-w-9\/12 {
  width: 75% !important;
}

.u-w-10\/12 {
  width: 83.333333% !important;
}

.u-w-11\/12 {
  width: 91.666667% !important;
}

.u-w-12\/12 {
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-w-auto {
    width: auto !important;
  }
  .md\:u-w-full {
    width: 100% !important;
  }
  .md\:u-w-screen {
    width: 100vw !important;
  }
  .md\:u-w-0 {
    width: 0 !important;
  }
  .md\:u-w-1\/12 {
    width: 8.333333% !important;
  }
  .md\:u-w-2\/12 {
    width: 16.666667% !important;
  }
  .md\:u-w-3\/12 {
    width: 25% !important;
  }
  .md\:u-w-4\/12 {
    width: 33.333333% !important;
  }
  .md\:u-w-5\/12 {
    width: 41.666667% !important;
  }
  .md\:u-w-6\/12 {
    width: 50% !important;
  }
  .md\:u-w-7\/12 {
    width: 58.333333% !important;
  }
  .md\:u-w-8\/12 {
    width: 66.666667% !important;
  }
  .md\:u-w-9\/12 {
    width: 75% !important;
  }
  .md\:u-w-10\/12 {
    width: 83.333333% !important;
  }
  .md\:u-w-11\/12 {
    width: 91.666667% !important;
  }
  .md\:u-w-12\/12 {
    width: 100% !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-w-auto {
    width: auto !important;
  }
  .sm\:u-w-full {
    width: 100% !important;
  }
  .sm\:u-w-screen {
    width: 100vw !important;
  }
  .sm\:u-w-0 {
    width: 0 !important;
  }
  .sm\:u-w-1\/12 {
    width: 8.333333% !important;
  }
  .sm\:u-w-2\/12 {
    width: 16.666667% !important;
  }
  .sm\:u-w-3\/12 {
    width: 25% !important;
  }
  .sm\:u-w-4\/12 {
    width: 33.333333% !important;
  }
  .sm\:u-w-5\/12 {
    width: 41.666667% !important;
  }
  .sm\:u-w-6\/12 {
    width: 50% !important;
  }
  .sm\:u-w-7\/12 {
    width: 58.333333% !important;
  }
  .sm\:u-w-8\/12 {
    width: 66.666667% !important;
  }
  .sm\:u-w-9\/12 {
    width: 75% !important;
  }
  .sm\:u-w-10\/12 {
    width: 83.333333% !important;
  }
  .sm\:u-w-11\/12 {
    width: 91.666667% !important;
  }
  .sm\:u-w-12\/12 {
    width: 100% !important;
  }
}
.u-h-auto {
  height: auto !important;
}

.u-h-full {
  height: 100% !important;
}

.u-h-screen {
  height: 100vw !important;
}

.u-h-0 {
  height: 0 !important;
}

.u-h-1\/12 {
  height: 8.333333% !important;
}

.u-h-2\/12 {
  height: 16.666667% !important;
}

.u-h-3\/12 {
  height: 25% !important;
}

.u-h-4\/12 {
  height: 33.333333% !important;
}

.u-h-5\/12 {
  height: 41.666667% !important;
}

.u-h-6\/12 {
  height: 50% !important;
}

.u-h-7\/12 {
  height: 58.333333% !important;
}

.u-h-8\/12 {
  height: 66.666667% !important;
}

.u-h-9\/12 {
  height: 75% !important;
}

.u-h-10\/12 {
  height: 83.333333% !important;
}

.u-h-11\/12 {
  height: 91.666667% !important;
}

.u-h-12\/12 {
  height: 100% !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-h-auto {
    height: auto !important;
  }
  .md\:u-h-full {
    height: 100% !important;
  }
  .md\:u-h-screen {
    height: 100vw !important;
  }
  .md\:u-h-0 {
    height: 0 !important;
  }
  .md\:u-h-1\/12 {
    height: 8.333333% !important;
  }
  .md\:u-h-2\/12 {
    height: 16.666667% !important;
  }
  .md\:u-h-3\/12 {
    height: 25% !important;
  }
  .md\:u-h-4\/12 {
    height: 33.333333% !important;
  }
  .md\:u-h-5\/12 {
    height: 41.666667% !important;
  }
  .md\:u-h-6\/12 {
    height: 50% !important;
  }
  .md\:u-h-7\/12 {
    height: 58.333333% !important;
  }
  .md\:u-h-8\/12 {
    height: 66.666667% !important;
  }
  .md\:u-h-9\/12 {
    height: 75% !important;
  }
  .md\:u-h-10\/12 {
    height: 83.333333% !important;
  }
  .md\:u-h-11\/12 {
    height: 91.666667% !important;
  }
  .md\:u-h-12\/12 {
    height: 100% !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-h-auto {
    height: auto !important;
  }
  .sm\:u-h-full {
    height: 100% !important;
  }
  .sm\:u-h-screen {
    height: 100vw !important;
  }
  .sm\:u-h-0 {
    height: 0 !important;
  }
  .sm\:u-h-1\/12 {
    height: 8.333333% !important;
  }
  .sm\:u-h-2\/12 {
    height: 16.666667% !important;
  }
  .sm\:u-h-3\/12 {
    height: 25% !important;
  }
  .sm\:u-h-4\/12 {
    height: 33.333333% !important;
  }
  .sm\:u-h-5\/12 {
    height: 41.666667% !important;
  }
  .sm\:u-h-6\/12 {
    height: 50% !important;
  }
  .sm\:u-h-7\/12 {
    height: 58.333333% !important;
  }
  .sm\:u-h-8\/12 {
    height: 66.666667% !important;
  }
  .sm\:u-h-9\/12 {
    height: 75% !important;
  }
  .sm\:u-h-10\/12 {
    height: 83.333333% !important;
  }
  .sm\:u-h-11\/12 {
    height: 91.666667% !important;
  }
  .sm\:u-h-12\/12 {
    height: 100% !important;
  }
}
.u-visible {
  visibility: visible !important;
}

.u-invisible {
  visibility: hidden !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-visible {
    visibility: visible !important;
  }
  .md\:u-invisible {
    visibility: hidden !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-visible {
    visibility: visible !important;
  }
  .sm\:u-invisible {
    visibility: hidden !important;
  }
}
.u-border-collapse {
  border-collapse: collapse !important;
}

.u-border-separate {
  border-collapse: separate !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-border-collapse {
    border-collapse: collapse !important;
  }
  .md\:u-border-separate {
    border-collapse: separate !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-border-collapse {
    border-collapse: collapse !important;
  }
  .sm\:u-border-separate {
    border-collapse: separate !important;
  }
}
.u-table-auto {
  table-layout: auto !important;
}

.u-table-fixed {
  table-layout: fixed !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-table-auto {
    table-layout: auto !important;
  }
  .md\:u-table-fixed {
    table-layout: fixed !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-table-auto {
    table-layout: auto !important;
  }
  .sm\:u-table-fixed {
    table-layout: fixed !important;
  }
}
.u-italic {
  font-style: italic !important;
}

.u-not-italic {
  font-style: normal !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-justify {
  text-align: justify !important;
}

.u-underline {
  text-decoration: underline !important;
}

.u-line-through {
  text-decoration: line-through !important;
}

.u-no-underline {
  text-decoration: none !important;
}

.u-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.u-overflow-clip {
  text-overflow: clip !important;
}

.u-align-baseline {
  vertical-align: baseline !important;
}

.u-align-top {
  vertical-align: top !important;
}

.u-align-middle {
  vertical-align: middle !important;
}

.u-align-bottom {
  vertical-align: bottom !important;
}

.u-align-text-top {
  vertical-align: text-top !important;
}

.u-align-text-bottom {
  vertical-align: text-bottom !important;
}

.u-whitespace-normal {
  white-space: normal !important;
}

.u-whitespace-nowrap {
  white-space: nowrap !important;
}

.u-whitespace-pre {
  white-space: pre !important;
}

.u-whitespace-pre-line {
  white-space: pre-line !important;
}

.u-whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.u-break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.u-break-words {
  overflow-wrap: break-word !important;
}

.u-break-all {
  word-break: break-all !important;
}

.u-indent {
  text-indent: 1em !important;
}

@media screen and (max-width: 1024px) {
  .md\:u-italic {
    font-style: italic !important;
  }
  .md\:u-not-italic {
    font-style: normal !important;
  }
  .md\:u-text-right {
    text-align: right !important;
  }
  .md\:u-text-center {
    text-align: center !important;
  }
  .md\:u-text-left {
    text-align: left !important;
  }
  .md\:u-text-justify {
    text-align: justify !important;
  }
  .md\:u-underline {
    text-decoration: underline !important;
  }
  .md\:u-line-through {
    text-decoration: line-through !important;
  }
  .md\:u-no-underline {
    text-decoration: none !important;
  }
  .md\:u-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
  .md\:u-overflow-clip {
    text-overflow: clip !important;
  }
  .md\:u-align-baseline {
    vertical-align: baseline !important;
  }
  .md\:u-align-top {
    vertical-align: top !important;
  }
  .md\:u-align-middle {
    vertical-align: middle !important;
  }
  .md\:u-align-bottom {
    vertical-align: bottom !important;
  }
  .md\:u-align-text-top {
    vertical-align: text-top !important;
  }
  .md\:u-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .md\:u-whitespace-normal {
    white-space: normal !important;
  }
  .md\:u-whitespace-nowrap {
    white-space: nowrap !important;
  }
  .md\:u-whitespace-pre {
    white-space: pre !important;
  }
  .md\:u-whitespace-pre-line {
    white-space: pre-line !important;
  }
  .md\:u-whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }
  .md\:u-break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }
  .md\:u-break-words {
    overflow-wrap: break-word !important;
  }
  .md\:u-break-all {
    word-break: break-all !important;
  }
  .md\:u-indent {
    text-indent: 1em !important;
  }
}
@media screen and (max-width: 599px) {
  .sm\:u-italic {
    font-style: italic !important;
  }
  .sm\:u-not-italic {
    font-style: normal !important;
  }
  .sm\:u-text-right {
    text-align: right !important;
  }
  .sm\:u-text-center {
    text-align: center !important;
  }
  .sm\:u-text-left {
    text-align: left !important;
  }
  .sm\:u-text-justify {
    text-align: justify !important;
  }
  .sm\:u-underline {
    text-decoration: underline !important;
  }
  .sm\:u-line-through {
    text-decoration: line-through !important;
  }
  .sm\:u-no-underline {
    text-decoration: none !important;
  }
  .sm\:u-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
  .sm\:u-overflow-clip {
    text-overflow: clip !important;
  }
  .sm\:u-align-baseline {
    vertical-align: baseline !important;
  }
  .sm\:u-align-top {
    vertical-align: top !important;
  }
  .sm\:u-align-middle {
    vertical-align: middle !important;
  }
  .sm\:u-align-bottom {
    vertical-align: bottom !important;
  }
  .sm\:u-align-text-top {
    vertical-align: text-top !important;
  }
  .sm\:u-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .sm\:u-whitespace-normal {
    white-space: normal !important;
  }
  .sm\:u-whitespace-nowrap {
    white-space: nowrap !important;
  }
  .sm\:u-whitespace-pre {
    white-space: pre !important;
  }
  .sm\:u-whitespace-pre-line {
    white-space: pre-line !important;
  }
  .sm\:u-whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }
  .sm\:u-break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }
  .sm\:u-break-words {
    overflow-wrap: break-word !important;
  }
  .sm\:u-break-all {
    word-break: break-all !important;
  }
  .sm\:u-indent {
    text-indent: 1em !important;
  }
}